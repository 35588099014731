.wrapper {
  display: flex;
  align-items: center;
}

.label {
  font-size: var(--theme-font-size-captionMobile);
  font-weight: var(--theme-font-weight-bold);
  margin-right: var(--theme-spacing-8);

  /* TODO: Update to theme media query variable once that is working on NCOM */
  /* https://noa-mktg.atlassian.net/browse/WDEV-5238 */
  @media (min-width: 1025px) {
    font-size: var(--theme-font-size-captionDesktop);
  }
}

.typeLabel {
  display: inline-block;
  width: fit-content;
  background-color: var(--theme-color-lightGray4);
  padding: var(--theme-spacing-8) var(--theme-spacing-16);
  border-radius: var(--theme-borderRadiusSmall);
  font-weight: 300;
  font-size: var(--theme-font-size-captionMobile);

  /* TODO: Update to theme media query variable once that is working on NCOM */
  /* https://noa-mktg.atlassian.net/browse/WDEV-5238 */
  @media (min-width: 1025px) {
    font-size: var(--theme-font-size-captionDesktop);
  }
}
