.firstNewsTiles {
  grid-column: 1 / span 2;
}

.gridOne {
  @media (--theme-mediaQuery-tablet) {
    grid-column: 1 / span 2;
  }
}

.gridThree {
  @media (--theme-mediaQuery-tablet) {
    grid-column: 3 / span 2;
  }
}
